.header-container {
  background-color: #8ec5fc;

  // background-image: linear-gradient(to top, #6a85b6 0%, #bac8e0 100%);
  // background-image: linear-gradient(-225deg, #2CD8D5 0%, #6B8DD6 48%, #8E37D7 100%);
  // background-image: linear-gradient(-225deg, #CBBACC 0%, #2580B3 100%);
  background-image: linear-gradient(180deg, #a9c9ff 0%, #ffbbec 100%);
}

@keyframes floatText {
  to {
    transform: translateX(-50%);
    opacity: 1;
  }
}

.header {
  display: flex;
  position: relative;

  .title {
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 16px;
    font-size: 44px;
    font-weight: bold;
    color: #333;
    opacity: 0;
    text-shadow: 0px 0px 5px #fff;

    transform: translateX(-20%);
    animation: floatText 1s 1 cubic-bezier(0, 0.71, 0.15, 1);
    animation-delay: 200ms;
    animation-fill-mode: forwards;

    @media screen and (max-width: 620px) {
      top: 42%;
    }
  }

  .subtitle {
    position: absolute;
    background-color: #5a4f40dd;
    border-radius: 4px;
    font-size: 18px;
    padding: 2px 9px;
    opacity: 0;
    white-space: nowrap;

    transform: translateX(-20%);
    animation: floatText 1s 1 cubic-bezier(0, 0.71, 0.15, 1);
    animation-delay: 400ms;
    animation-fill-mode: forwards;
  }

  .s1 {
    top: 62%;
    animation-delay: 400ms;
    left: 45%;

    @media screen and (max-width: 620px) {
      display: none;
      left: 48%;
      top: 44%;
    }
  }

  .s2 {
    top: 69%;
    animation-delay: 800ms;
    left: 56%;

    @media screen and (max-width: 1024px) {
      top: 69%;
    }
    @media screen and (max-width: 620px) {
      display: none;
      top: 54%;
    }
  }

  .s3 {
    top: 76%;
    animation-delay: 1200ms;
    left: 58%;

    @media screen and (max-width: 1024px) {
      top: 76%;
    }
    @media screen and (max-width: 620px) {
      display: none;
      top: 64%;
    }
  }

  .big-logo {
    width: 960px;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 1216px) {
      width: 912px;
    }

    @media screen and (max-width: 1024px) {
      width: 90%;
      margin-left: 8px;
      margin-right: 8px;
    }
  }
}
