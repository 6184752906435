.about {
  margin-top: 56px;
  font-size: 16px;
  background-color: #39373c;
  border-radius: 8px;
  padding: 24px;
  color: #e4e4e4d1;

  .inner {
    width: 60%;
    margin-right: auto;
    margin-left: 0;

    @media screen and (max-width: 1216px) {
      width: 80%;
    }

    @media screen and (max-width: 1024px) {
      width: auto;
    }
  }

  .title {
    font-size: 22px;
    color: #fff;
  }
}
