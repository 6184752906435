.card {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;

  .blockchain {
    font-size: 22px;
    margin-top: 8px;
    color: #ffffff;
  }

  .more-info {
    color: #ffffff66;
  }
}

/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
  height: 200px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */

  border-radius: 8px;
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  border-radius: 8px;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  // background-image: radial-gradient( circle 939px at 0.7% 2.4%,  rgba(116,106,255,1) 0%, rgba(221,221,221,1) 100.2% );
  // background-image: radial-gradient( circle farthest-corner at 50.3% 44.5%,  rgba(116,147,179,1) 0%, rgba(62,83,104,1) 100.2% );
  background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  // background-image: linear-gradient(315deg, #7f53ac 0%, #647dee 74%);
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .logo-img {
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #ffffffaa;
  }
  .block-logo {
    width: 100px;
    // position:absolute;
  }
  .reward {
    // font-size: 12px;
  }
}

/* Style the back side */
.flip-card-back {
  display: flex;
  align-items: center;
  flex-direction: column;

  background-color: rgba(30, 143, 255, 0.35);
  color: white;
  transform: rotateY(180deg);

  .block-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1;

    h4 {
      font-size: 18px;
    }
  }

  .button-container {
    height: 60px;
  }
}

.modal-body {
  display: flex;

  .qr {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 8px;
    margin-right: 12px;
  }

  .modal-info {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex: 1;
  }

  .delegate {
    display: flex;
    justify-content: space-between;

    .address {
      background-color: #00000066;
      border: 1px solid #555;
      padding: 4px 12px;
      border-radius: 4px;

      img {
        width: 13px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
}
