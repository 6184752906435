.stake {
  background: linear-gradient(to bottom, #152231 0%, #15223100 10%);

  .label {
    background: linear-gradient(to bottom, #090d10, #152231);
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    top: -60px;
    position: relative;
    height: 60px;
    border-radius: 10px 10px 0 0;
    line-height: 60px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;


    @media screen and (max-width: 620px) {
      font-size: 16px;
      height: 40px;
      top: -40px;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0px 0px;
    grid-gap: 32px;

    @media screen and (max-width: 620px) {
      grid-template-columns: 1fr;
    }
  }
}
