body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: linear-gradient(to top, #141e30, #243b55) !important;
  min-height: 100vh;
  padding-bottom: 64px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  width: 1140px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 1216px) {
    width: 1012px;
  }

  @media screen and (max-width: 1024px) {
    width: auto;
    margin-left: 8px;
    margin-right: 8px;
  }
}

a {
  color: #d5f3a6 !important;

  &:hover {
    color: #9ce723 !important;
  }
}